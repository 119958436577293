<template>
  <div>
    <h2 class="theme-text text-center mt-5" title="Uocharcoal: Home of Best of Nigeria Charcoal">Best of Nigeria Charcoal</h2>
    <v-row class="my-3">
      <v-col cols="12" sm="6">
        <video controls style=" max-height: 50vh; width: 100%" title="Ayin Charcoal Hardwood, Best of Nigeria Charcoal video">
          <source src="uploads/ayin.mp4" type="">
        </video>
      </v-col>
      <v-col>
        <v-card elevation="3" class=" pa-2">
          <h3 class="theme-text" title="Ayin Charcoal Nigeria">Ayin Charcoal</h3>
          <br>
          <div>
            <span class=" d-block" title="Ayin Charcoal Hardwood type: Birch">Hardwood type ~ Birch</span>
            <span class=" d-block" title="Ayin Charcoal Hardwood Moisture level"> Moisture level ~ 3% - 4%</span>
            <span class=" d-block" title="Ayin Charcoal Hardwood Burns">Burns ~ 2hrs - 3hrs</span>
            <span class=" d-block" title="Ayin Charcoal Hardwood Resturant size">Resturant size ~ 50mm to 150mm</span>
            <span class=" d-block" title="Ayin Charcoal Hardwood Shisha grade">Shisha grade ~ 20mm to 70mm</span>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <hr>
    <v-row class="my-3">
      <v-col cols="12" sm="6">
        <video controls style=" max-height: 50vh; width: 100%" title="Non Sparking Charcoal Hardwood, Best of Nigeria Charcoal video">
          <source src="uploads/nospark.mp4" type="">
        </video>
      </v-col>
      <v-col>
        <v-card elevation="3" class=" pa-2">
          <h3 class="theme-text" title="Non Sparking Charcoal Hardwood">Non Sparking Charcoal</h3>
          <br>
          <div>
            <span class=" d-block" title="Non Sparking Charcoal Hardwood Rose, Ahara, Mahogany, etc.">Hardwood ~ Rose, Ahara, Mahogany, etc.</span>
            <span class=" d-block" title="Non Sparking Charcoal Hardwood Moisture level"> Moisture level ~ 3% - 4%</span>
            <span class=" d-block" title="Non Sparking Charcoal Hardwood Burns">Burns ~ maximum 2hrs</span>
            <span class=" d-block" title="Non Sparking Charcoal Hardwood Resturant size">Resturant size ~ 50mm to 150mm</span>
            <span class=" d-block" title="Non Sparking Charcoal Hardwood Bbq">Bbq ~ 20mm to 70mm</span>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ProductSlide"

}
</script>
