<template>
  <div>
    <product-slide></product-slide>
  </div>
</template>

<script>
import ProductSlide from '../components/ProductSlide.vue';


export default {
  name: "Products",
  data() {
    return {
      //
    };
  },
  components: {ProductSlide},
};
</script>
